import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { ButtonBase, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PhotoProvider, PhotoView } from "react-photo-view";
// import { ArrowDropDown } from "@mui/icons-material";
import Marquee from "react-fast-marquee";
import axios from "axios";
import profile from "../../../assets/Ellipse 5@2x.png";
import dropdown from "../../../assets/dropdown.png";
import signout from "../../../assets/Sign out.svg";
import { lowerDashJoinStr, allWordsCapitalize } from "../../../utils/apiutils";
import DeleteModal from "../../components/DeleteModal/deletemodal";
import "./index.css";
import HeaderDropdown from "../../components/Input/headerDropdown";
import { useDispatch } from "react-redux";
import { logout } from "../../../User/layout/header/reducers";

const Navigations = [
  "Dashboard",
  "Grand Prix",
  "GP Leagues",
  "Fantasy Leagues",
  "Users",
  "Games",
  "Tournaments",
  "Ladders",
  // "Transactions",
  "Inventory",
];
const profImgPath = process.env.REACT_APP_BASE_URL + "/Uploads/adminpic.jpg";
const ResultMenu = [
  "Tournament Results",
  "Ladder Results",
  // "Total War Ladder Results",
  "Match Results",
  "GP League Results",
];

const Index = () => {
  const dispatch = useDispatch();
  const [matchData, setMatchData] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  // const [profileDropdown, setProfileDropdown] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    fetchMatchData();

  }, []);

  setTimeout(() => {
    fetchMatchData();
  }, 180000);

  useEffect(() => {
    if (params["*"]) {
      let path = params["*"]?.split("/")[1];
      path = allWordsCapitalize(path?.split("-")?.join(" "));
      setSelectedHeader(path);
    }
  }, []);

  const fetchMatchData = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/ticker`,
        config
      );
      setMatchData(response.data.result);
    } catch (error) {
      console.log(error, "catch block");
    }
  };
  const logoutHandler = () => {
    // dispatch(logout());
    localStorage.removeItem("adminToken");
    window.location.replace("/auth/login");
  };
  const logoClickHandler = () => {
    navigate("/admin/dashboard");
    setSelectedHeader("Dashboard");
  };
  return (
    <div className="admin-nav-bar">
      <DeleteModal
        open={confirmModal}
        handleClose={() => setConfirmModal(false)}
        confirmDeleteHandler={logoutHandler}
      />
      <nav className="navbar navbar-expand-lg admin-navbar-custom ">
        <div className="container-fluid">
          <span className="navbar-brand" onClick={logoClickHandler}>
            Online <br /> Battleground
          </span>
          <button
            className="navbar-toggler navbar-toggler-right"
            data-target="#navbarSupportedContent"
            data-toggle="collapse"
            type="button"
          >
            <span className="navbar-toggler-icon">
              <i
                className="fa fa-navicon"
                style={{ color: "#fff", fontSize: "28px" }}
              ></i>
            </span>
          </button>
          <div
            className="justify-content-end collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              {Navigations.map((x, i) => (
                <li className={`nav-item`} key={i}>
                  <Link
                    className="nav-link header-nav"
                    to={`/admin/${lowerDashJoinStr(x)}`}
                    onClick={() => setSelectedHeader(x)}
                  >
                    <ButtonBase centerRipple={true}>
                      <span
                        className={`${selectedHeader === x ? "LHeader-active" : "null"
                          }`}
                      >
                        {x}
                      </span>
                    </ButtonBase>
                  </Link>
                </li>
              ))}
              <HeaderDropdown
                title="Results"
                elems={ResultMenu}
                setSelectedHeader={setSelectedHeader}
              />

              <li style={{ display: "flex" }} class="nav-item dropdown">
                <PhotoProvider bannerVisible={false}>
                  <PhotoView src={profImgPath}>
                    <img
                      height="30px"
                      src={profImgPath}
                      width="30px"
                      className="admin-prof-pic"
                    />
                  </PhotoView>
                </PhotoProvider>
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></a>
                <div
                  class="dropdown-menu profile-dropdown"
                  aria-labelledby="navbarDropdown"
                >
                  <a
                    class="dropdown-item"
                    onClick={() => setConfirmModal(true)}
                  >
                    <span className="admin-header-signout">
                      <img alt="" src={signout} /> Logout
                    </span>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item"
                    onClick={() => navigate("/admin/change-password")}
                  >
                    Change password
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Marquee
        style={{ fontFamily: "Roboto", fontWeight: "500", marginBottom: "10px",  background: "rgb(234, 135, 68)" }}
        pauseOnHover="true"
      >
        {matchData?.map((match, index) => (
          <div key={index} style={{ marginRight: "20px" }}>
            {match}
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default Index;
