import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "./index.module.css";
import { NavLink } from "react-router-dom";
import "./stylesheet.css";

import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";

import { getUsers, setNavText, logout } from "./reducers";

import { getFriends } from "../../pages/TeamProfile/reducers";

const BASE_URL = process.env.REACT_APP_BASE_URL + "/";
import { Card } from "./components";
import "./stylesheet.css";
import Games from "./components/games/games";
import backgroundHomeImage from "../../../assets/images/home-background-image.png";
import codIcon from "../../../assets/images/callOfDuty.png";

import winHash from "../../../assets/images/winHash.png";
import winCup from "../../../assets/images/winCup.png";
import winTimer from "../../../assets/images/winTimer.png";
import winFight from "../../../assets/images/winFight.png";
import { Button } from "react-bootstrap";
import LadderCard from "./components/laddersCard";
import AgeModal from "./components/ageModal";

const Home = () => {
  const Header = ["Home", "Games", "Grand Prix", "Rules"];
  const [searchVal, setSearchVal] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [token, setToken] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { users, headerText } = useSelector((state) => state.userHeader);
  const { profile, setprofile } = useSelector((state) => state?.userProfile);
  const { friends } = useSelector((state) => state.userTeamProfile);
  const [urlParam, setUrlParam] = useState(params["*"]);

  const [tournamentsData, setTournamentsData] = useState([]);
  const [laddersData, setLaddersData] = useState([]);
  const [showNav, setShowNav] = useState(false);

  const tournamentsSection = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let fetchRes = fetch(
      `${process.env.REACT_APP_BASE_URL}/api/auth/allTournaments`,
      requestOptions
    );
    fetchRes
      .then((response) => response.json())
      .then((json) => {
        if (json) {
          setTournamentsData(json.tournamentData);
        }
      })
      .catch((e) => { });
  };
  const laddersSection = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let fetchRes = fetch(
      `${process.env.REACT_APP_BASE_URL}/api/auth/allLadders`,
      requestOptions
    );
    fetchRes
      .then((response) => response.json())
      .then((json) => {
        if (json) {
          setLaddersData(json.ladderData);
        }
      })
      .catch((e) => { });
  };
  useEffect(() => {
    tournamentsSection();
    laddersSection();
  }, []);
  useEffect(() => {
    //   if (params["*"]) {
    //     const path = localStorage.getItem("navText");
    //     dispatch(setNavText(path));
    //   }
    const userToken = localStorage.getItem("userToken");
    setToken(userToken);
    if (userToken) {
      dispatch(getFriends());
    }
  }, []);
  const checkSession = () => { };
  const friendIds = friends?.map((friend) => friend?._id);

  const userLogoutHandler = () => {
    dispatch(logout());
    const setter = setTimeout(() => {
      localStorage.removeItem("userToken");
      localStorage.removeItem("user_id");
      localStorage.setItem("navText", "Profile");
      window.location.replace("/auth/login");
    }, 300);
    setter();
  };
  const refreshHandler = () => {
    navigate("/home");
    checkSession();
  };
  const searchHandler = (val) => {
    setSearchVal(val);
    dispatch(getUsers({ query: val }));
  };
  const userClickHandler = (id) => {
    setSearchVal("");
    navigate("/user/search-user-profile/" + id);
  };
  const headerTextHandler = (val) => {
    localStorage.setItem("navText", val);
    dispatch(setNavText(val));
  };
  const searchClearHandler = () => {
    setSearchVal("");
  };
  // const notifClickHandler = (e) => {
  // };

  const toggleNavItems = () => {
    setShowNav(!showNav);
  };

  return (
    <>
      {/* <WidgetBot
        server="299881420891881473"
        channel="355719584830980096"
      /> */}
      <nav className="navbar navbar-expand-lg navbar-custom ">
        <div className="container-fluid">
          <a className="navbar-brand" onClick={refreshHandler}>
            Online <br /> Battleground
          </a>
          {/* <button
            className="navbar-toggler navbar-toggler-right"
            data-target="#navbarSupportedContent"
            data-toggle="collapse"
            type="button"
          >
            <span className="navbar-toggler-icon">
              <i
                className="fa fa-navicon"
                style={{ color: "#fff", fontSize: "28px" }}
              ></i>
            </span>
          </button> */}
          <div className="justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <NavLink to="/home" className="nav-link header-nav">
                Home
              </NavLink>

              <NavLink
                to={token ? "/user/games" : "/auth/login"}
                className="nav-link header-nav"
              >
                Games
              </NavLink>

              <NavLink
                to={token ? "user/grand-prix" : "/auth/login"}
                className="nav-link header-nav"
              >
                Grand Prix
              </NavLink>

              <NavLink
                to={token ? "user/rules" : "/auth/login"}
                className="nav-link header-nav"
              >
                Rules
              </NavLink>

              <li>
                {localStorage.getItem("userToken") ? (
                  <></>
                ) : (
                  <Button
                    style={{
                      color: "#ea8744",
                      border: "1px solid",
                      marginLeft: "15px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      navigate("/auth/login");
                    }}
                  >
                    Login
                  </Button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div
        className={"main"}
        style={{ backgroundImage: `url(${backgroundHomeImage})` }}
      >
        {/* <div className={"content"}>
          <h1>Heroes are destined to fail</h1>
          <h2>Legends were born to die</h2>
          <h2>
            And Tyrants live to rule...
          </h2>
        </div> */}
        <div className="join-content" style={{width:"60%", height:"220px",display:'flex',  flexDirection:"column",justifyContent:"center",height:"591px",alignItems:"center"}}>
              <span>FREE ENTRY $200</span><br /> <span>30 DAY LADDER</span> <br />{" "}
              <span>GRAND PRIX</span>
            </div>
      </div>
      <div  className="container">
        <div className="mt-4 mb-4">
          <h3 style={{ color: "white" }}>Featured Tournaments</h3>
          <Grid
            container
            columnSpacing={5}
            style={{ marginTop: 15, marginBottom: 30 }}
          >
            {/* <Grid>
              <Card tournament="T20" />
            </Grid> ; */}
            {tournamentsData &&
              tournamentsData.map((element, index) => {
                return (
                  <Grid key={index} item>
                    <Card tournament={element} />
                  </Grid>
                );
              })}
          </Grid>
        </div>
        <div className={"follow-page"}>
          <div className={"follow-box"}>
            <div className={"follow-content"}>
              <h2>ONLINE BAttleground For Updates</h2>
            </div>
            <div className={"follow-button"}>
              <Link
                className="bt"
                to="/auth/login"
                onClick={(e) => notifClickHandler(e)}
              >
                <button>Follow</button>
              </Link>
            </div>
          </div>
        </div>
        {/* // win to earn section */}
        <div className="win-to-page">
          <div className="win-to-content">
            {/* <img src={winHash} alt="" /> */}
            <h3>Promotion and Relegation Ladders</h3>
            <br />
            <h3>Weekly Tournaments </h3>
            <p>
              Show your gaming skills, compete and win to earn as much you can
            </p>
          </div>
          <div className="win-to-box">
            <div className="box-tournament">
              <div className="tournament-content">
                <img src={winCup} alt="" />
                <h6>Tournaments | Leagues | Ladders</h6>
                <p>
                  Organize or Compete in any of our tournaments, leagues or
                  ladders and win prizes.
                </p>
              </div>
              <div className="tournament-button">
                <Link
                  className="bt"
                  to="/auth/login"
                  onClick={(e) => notifClickHandler(e)}
                >
                  <button>Get Started</button>
                </Link>
              </div>
            </div>

            <div className="box-playanyone">
              <div className="playanyone-content">
                <img src={winTimer} alt="" />
                <h6>Play Anytime</h6>
                <p>Compete anywhere anytime in different modes of the games</p>
              </div>
              <div className="playanyone-button">
                <Link
                  className="bt"
                  to="/auth/login"
                  onClick={(e) => notifClickHandler(e)}
                >
                  <button>Get Started</button>
                </Link>
              </div>
            </div>

            <div className="box-challenge">
              <div className="challenge-content">
                <img src={winFight} alt="" />
                <h6>Challenge</h6>
                <p>
                  Can challenge and compete with any player across the platform
                </p>
              </div>
              <div className="challenge-button">
                <Link
                  className="bt"
                  to="/auth/login"
                  onClick={(e) => notifClickHandler(e)}
                >
                  <button>Get Started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* // win ton earn section end  */}

        <div className={classes.ladderDiv}>
          <h3 style={{ marginTop: 20 }} className={classes.laddersh1}>
            Featured Ladders
          </h3>
          <Grid
            container
            columnSpacing={5}
            style={{ marginTop: 15, marginBottom: 30 }}
          >
            {/* <Grid>
              <Card tournament="T20" />
            </Grid> ; */}
            {laddersData &&
              laddersData.map((element, index) => {
                return (
                  <Grid key={index} item>
                    <LadderCard ladder={element} />
                  </Grid>
                );
              })}
          </Grid>
        </div>

        <div style={{ margin: 85, marginLeft: 0 }} className="join-section">
          <img src={codIcon} alt="" style={{ height: 240 }} />
          <div className="content" >
          <h1>Heroes are destined to fail</h1>
          <h1>Legends were born to die</h1>
          <h2>
            And Tyrants live to rule...
          </h2>
        </div>
          {/* <div style={{display:'flex', alignItems:'flex-start', flexDirection:"column",justifyContent:"center"}} className="join-content">
              <span>FREE ENTRY $200</span><br /> <span>30 DAY LADDER</span> <br />{" "}
              <span>GRAND PRIX</span>
            </div> */}
            
          
        </div>
        <div style={{ display: "flex", justifyContent: "center" }} className="join-box">
           
            {/* <div className="join-button">
              <Link
                className="bt"
                to="/auth/login"
                onClick={(e) => notifClickHandler(e)}
              >
                <button>Join</button>
              </Link>
            </div> */}
            <div style={{marginTop:"35px"}} className="join-button">
              <Link
                className="bt"
                to="/auth/login"
              // onClick={(e) => notifClickHandler(e)}
              >
                <button style={{ fontSize: "16px" }} >Create Account</button>
              </Link>
            </div>
            <div style={{marginTop:"35px"}} className="join-button">
              <Link
                className="bt"
                to="/user/my-teams"
              // onClick={(e) => notifClickHandler(e)}
              >
                <button style={{ fontSize: "16px" }} >Create Team</button>
              </Link>
            </div>
            <div style={{marginTop:"35px"}} className="join-button">
              <Link
                className="bt"
                to="/user/games"
              // onClick={(e) => notifClickHandler(e)}
              >
                <button style={{ fontSize: "16px" }} >Enter Matches</button>
              </Link>
            </div>
          </div>
        <div className={classes.gamesDiv}>
          <Games />
        </div>
      </div>

      <footer>
        <div className="footer-distributed">
          <div className="footer-left">
            <span className="footer-link">
              <a className="link-1" href="#">
                ONLINE <br />
                BATTLEGROUND
              </a>
            </span>
            <p>Online Battleground &copy; 2022</p>
          </div>
          <div className="footer-right">
            <NavLink to="/contact">CONTACT US</NavLink>
            <NavLink to="/terms">TERMS OF SERVICE</NavLink>
            <NavLink to="/privacy">PRIVACY POLICY</NavLink>
          </div>
        </div>
      </footer>
      <AgeModal />
    </>
  );
};

export default Home;
