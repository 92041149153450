import { Formik } from "formik";
import React, { useState } from "react";
import Modal from "../../../../components/topModal/topModal";
import SelectField from "../../../../../Admin/components/Input/selectField";
import FormSubmitButton from "../../../../../Admin/components/FormSubmitButton/formSubmitbutton";

export default function joinTeamModal({
  open,
   
  handleClose,
  handleTeamInviteToFriend,
}) {
  const [friends,setFriends] = useState([]);
  const friendsArr=[];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const config = {
          headers: {
            Authorization: token,
          },
        };
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/user/allActiveUser`,
          config
        );
        setLoading(false);
        console.log(response.data.result);
        setFriends(response.data.result);
        if(friends){
          friends.map((x) => {
            friendsArr.push({ _id: x._id, gameName: x.fullName });
          });
        }
        
      } catch (error) {
        setLoading(false);
        // Notification.error(error.response.message);
      }
    };

    fetchData();
  }, []);

  // friends.map((x) => {
  //   friendsArr.push({ _id: x._id, gameName: x.fullName });
  // });

  

  return (
    <Modal open={open} handleClose={handleClose} widthe={500}>
      <Formik
        initialValues={{
          friendId: "",
        }}
        onSubmit={(values) => handleTeamInviteToFriend(values)}
      >
        {({ values, handleChange, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div style={{ marginTop: "7%" }}></div>
              <SelectField
                placeholder="Select Friend"
                name="friendId"
                type="text"
                required={true}
                onchange={handleChange}
                value={values.friendId}
                items={friendsArr}
              />
              <FormSubmitButton title={"Add"} clickHandler={function () {}} />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}
